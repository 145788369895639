@import "../../../../Variables.scss";

.step-container,
.wizard-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 60vh;
	margin: 50px auto;
	padding: 20px;
	text-align: center;

	.step-element {
		margin: 15px 0 15px 0;
		font-family: "NORLYSText-Regular";

		@include medium {
			margin: 0;
		}
	}

	@include large {
		width: 95%;
	}
}

.step-title-section {
	font-family: "NORLYSHeadline-Bold";
	font-size: 40px;
	text-align: left;
	margin-bottom: 48px;

	@include small {
		display: none;
	}
}

.nl-chevron {
	overflow-y: inherit;

	.nl-chevron-footer {
		margin: 32px 130px 32px 130px !important;
	}

	.nl-chevron-header {
		@include small {
			justify-content: center !important;
		}
	}
}

.nl-chevron-content {
	margin-top: 5px !important;
}

.nl-checkbox-frame:not(:checked) {
	background-color: white;
}

.nl-checkbox-layout {
	max-width: 600px;
	font-family: "NORLYSText-Regular";
}

.nl-chevron-label {
	font-family: "NORLYSText-SemiBold" !important;
	margin-left: 130px !important;
}

.disabled-step{
	opacity: 0.5;
	pointer-events: none;
}

@media screen and(max-width:$medium) {
	.nl-chevron-label {
		font-family: "NORLYSText-SemiBold" !important;
		margin-left: 25px !important;
	}

	.nl-chevron-footer {
		padding-right: 0;
	}

	.title-section {
		display: none;
	}

	.app-container {
		margin: 0;
	}

	.wizard-container {
		margin: 0;
	}

	.step-container,
	.wizard-container {
		width: 100% !important;
		padding: 0;
	}

	.nl-chevron {
		min-height: 85vh !important;
		box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);

		.nl-chevron-footer {
			margin: 0 !important;
			background: #ffffff;
			box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
			bottom: 0;
			width: 100%;
			padding-top: 15px;
			position: fixed;
			height: 70px;
			z-index: 500;
		}

		.nl-chevron-content {
			margin: 0px 6px 0 10px !important;
		}
	}

	.nl-chevron-icons {
		display: none !important;
	}
}
