@import "../../../Variables.scss";

.step-container {
	width: 75%;
	min-height: 60vh;
	margin: 50px auto;
	padding: 20px;
	text-align: center;
	font-family: "NORLYSText-Regular";

	@include large {
		width: 95%;
	}
}

.title-section {
	font-family: "NORLYSHeadline-Bold";
	font-size: 40px;
	text-align: left;
	margin-bottom: 48px;
}

.step-element {
	margin: 15px 0 15px 0;
	font-family: "NORLYSText-Regular";

	@include medium {
		margin: 0;
	}
}
