@import "../../../Variables.scss";

.background-error {
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-radius: 6px;
	font-family: "NORLYSText-Regular";
	background-color: $Green-bright;
	margin-bottom: 32px;
}

.container {
	display: flex;
	flex: center 0.3;
	flex-direction: column;
	flex: 0.5;
}

.bullet-list {
	font-size: 18px;
	line-height: 26px;

	&p {
		margin-top: 0px;
	}
}

.headerText {
	margin-top: 32px;
	font-family: "NORLYSHeadline-Bold";
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 48px;
}
