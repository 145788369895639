@import "../../../Variables.scss";

.app-header {
	position: relative;

	.image-section{
		display: flex;
		min-height: 60px;

		.filler{
			flex:1;
		}

		.image-box
		{
			max-width: 132px;
			height: 60px;
			display: flex;
    		place-self: center;

			
			img,svg{
				max-height: 60px;
				max-width: -webkit-fill-available;
				max-width: -moz-available;				
			}

			svg{
				height: 100%;
				min-height: 60px;
			}
		}
	}
}

@media screen and (max-width: 768px) {

	.app-header {
		background: #ffffff;
		padding: 5px;
		height: 60px;
		box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
		z-index: 501;
	}
}
