@import "../../../../../Variables.scss";

.error-container {
	display: flex;
	align-items: center;
	border-radius: 6px;
	background-color: $Green-bright;
	margin-right: 3rem;

	@media screen and (max-width: 600px) {
		flex-direction: column;
		margin: 0;
	}
}

.text-container {
	font-family: "NORLYSText-Regular";
	font-size: 18px;
	line-height: 1.6;
	margin: 5rem;
	.error-title {
		line-height: 120%;
		font-family: "NORLYSHeadline-Bold";
	}

	@media screen and (max-width: 600px) {
		margin: 2rem;
	}
}

.image-container {
	img {
		max-width: 18rem;
	}
}
