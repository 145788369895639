@import "../../../Variables.scss";

.call-me-up-container{
	.inner-container {
		display: flex;
		flex-direction: column;
		flex: 0.5;
		margin-top: -8px;
	}
	
	.errorMessage {
		margin-top: 24px;
		margin-bottom: -24px;
		text-align: center;
		color: red;
		font-weight: bold;
	}
	
	.privacy-policy {
		margin-top: 10px;
		font-size: 14px !important;
		line-height: 18px;
		align-self: center;
	}
	
	h2 {
		margin-bottom: 0;
		text-align: left;
	}
	
	p {
		margin: 8px 0 32px;
		font-size: 18px;
		line-height: 26px;
		text-align: left;
	}
	
	.input {
		margin-bottom: 24px;
	}
	
	.buttons {
		margin-top: 56px;
		margin-right: 16px;
		margin-bottom: 32px;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
	}
	
	.idqButtons {
		margin-top: 64px;
		margin-bottom: 32px;
	}

}

