@import "../../../Variables.scss";

.notfound-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	overflow: hidden;

	.app-header {
		margin: 30px 0 0 10px;
	}

	.notfound-message {
		-webkit-box-shadow: 0px 4px 12px 4px rgba(229, 230, 232, 0.45);
		box-shadow: 0px 4px 12px 4px rgba(229, 230, 232, 0.45);
		position: absolute;
		background-color: #ffffff;
		padding: 32px;
		width: 600px;
		height: 300px;
		top: calc(50% - 150px);
		left: calc(50% - 300px);

		h1 {
			font-family: "NORLYSHeadline-Bold";
			text-align: center;
		}

		> div {
			font-family: "NORLYSText-Regular";
			text-align: center;
		}

		@include small {
			width: 100%;
			left: 0;
			max-width: -webkit-fill-available;
			max-width: -moz-available;
			max-width: stretch;
		}
	}
}
