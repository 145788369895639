//Breakpoints
$extrasmall:320px;
$small:576px;
$medium:768px;
$large:992px;
$extralarge:1440px;

//Strong colors
$Red-strong:#F8232D;
$Blue-strong:#0A77ED;
$Green-strong:#25D287;
$Purple-strong:#755FFA;
$Brown-strong:#694F38;
$Yellow-strong:#FFDB38;
$Turquise-strong:#369993;

//Subtle bright colours
$Red-bright:#FFDEE5;
$Blue-bright:#D4EEFA;
$Green-bright:#D3F4E8;
$Purple-bright:#E3E1FF;
$Brown-bright:#EEE7E2;

//Subtle dark colours
$Blue-dark:#002B6B;
$Green-dark:#103C23;

//Greyscale
$Grey-100:#272727;
$Grey-60:#838383;
$Grey-40:#B5B6B8;
$Grey-10:#E5E6E8;
$Grey-05:#F5F6F8;
$White:#FFFFFF;

// Spacings
$size2:2px;
$size4:4px;
$size8:8px;
$size16:16px;
$size24:24px;
$size32:32px;
$size40:40px;
$size48:48px;
$size56:56px;
$size64:64px;
$size72:72px;
$size80:80px;
$size96:96px;
$size120:120px;

@mixin extrasmall {
    @media (max-width: $extrasmall) { @content }
};

@mixin small {
    @media (max-width: $small) { @content }
};

@mixin medium {
    @media (max-width: $medium) { @content }
};

@mixin large {
    @media (max-width: $large) { @content }
};

@mixin extralarge {
    @media (max-width: $extralarge) { @content }
};

