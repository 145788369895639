@import "../../../../../Variables.scss";

.summary-content {
	margin: 0px 10px;
}

.summary-container {
	display: flex;
	flex-direction: column;
	font-family: "NORLYSText-Regular";

	> p {
		font-size: 20px;
		margin-bottom: 40px;

		@include medium {
			margin-bottom: 20px;
		}
	}

	.summary-row {
		display: flex;
		flex: 1;
		justify-content: space-evenly;
		flex-direction: row;
		margin-top: 15px;
		line-height: 24px;
		font-size: 20px;

		&.header-row {
			margin-top: 5px;
		}

		&.cpr-mobile {
			display: initial;
		}

		&.cpr-mobile {
			display: none;
		}

		@include medium {
			font-size: 18px;
			flex-direction: column;
			margin-top: 0;

			&.header-row {
				flex-direction: row !important;
			}

			.cpr-desktop {
				display: none !important;
			}

			&.cpr-mobile {
				display: initial !important;
			}
		}

		.summary-column {
			@include medium {
				margin-top: 10px;
			}

			display: flex;
			flex-direction: column;
			flex: 1;

			> div {
				flex: 1;
				align-items: flex-start;
			}

			&:nth-child(1) {
				flex: 2;
				min-width: 66%;
			}
		}
	}

	.nl-checkbox-label {
		font-size: 16px !important;
	}
}

.privacy-policy {
	margin-bottom: -8px;
	font-size: 18px;
	line-height: 21px;
}

.nl-checkbox-frame {
	top: 0 !important;
}

.terms-subtext {
	margin-top: 20px;
	font-size: 14px;
	padding-left: 50px;
	max-width: 550px;
	line-height: 21px;
}

.accept-marketing {
	line-height: 21px;
}

.token-container {
	margin-top: 10px;
}

#subTitle {
	margin-left: 0;
}

#subscriptionTextContainer {
	margin-top: 15px;
}

#conditionsText {
	margin-top: 30px;
}

@media screen and(max-width:$medium) {
	.summary-step {
		.nl-chevron-content {
			padding-bottom: 50%;
			padding-right: 10px;
			padding-left: 5px;
		}

		.nl-chevron-footer {
			flex-direction: column-reverse;
			height: 95px;

			button.Button {
				width: 96%;
				margin-left: 2%;
			}

			:nth-child(1).Button {
				border: none;
				background-color: transparent;
				color: $Grey-60;
				text-decoration: underline;
			}
		}
	}
}
