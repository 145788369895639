@import "../../../Variables.scss";

.background-banner {
	background-color: $Green-bright;
	padding: 15px;
	display: flex;
	flex-direction: column;
	contain: content;
	border-radius: 6px;
	font-family: "NORLYSText-Regular";

	@include medium {
		display: none !important;
	}
}
.title-section {
	line-height: 120%;
	font-family: "NORLYSHeadline-Bold";
	font-size: 60px;
	margin-top: 5px;
}

.hero-banner-img {
	align-self: center;
}

.hero-container {
	display: flex;
	flex-direction: column;
}

.hero-image-top-left,
.hero-image-top-center,
.hero-image-top-right {
	display: flex;
	flex-direction: column;
	align-self: center;
}

.hero-image-top-left img {
	align-self: start;
	margin-bottom: 2rem;
}

.hero-image-top-center img {
	align-self: center;
	margin-bottom: 2rem;
}

.hero-image-top-right img {
	align-self: end;
	margin-bottom: 2rem;
}

.hero-image-left,
.hero-image-right {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
}

.hero-image-left {
	flex-direction: row;
}

.hero-image-right {
	flex-direction: row-reverse;
}

.hero-image-left img {
	padding-right: 2rem;
}

.hero-image-right img {
	padding-left: 2rem;
}

.hero-image-bottom-left,
.hero-image-bottom-center,
.hero-image-bottom-right {
	display: flex;
	flex-direction: column-reverse;
	align-self: center;
}

.hero-image-bottom-left img {
	align-self: start;
	margin-bottom: 2rem;
}

.hero-image-bottom-center img {
	align-self: center;
	margin-bottom: 2rem;
}

.hero-image-bottom-right img {
	align-self: end;
	margin-bottom: 2rem;
}

.hero-container h1 {
	margin-bottom: 5px;
}

.imagewrapper {
	display: flex;
	height: auto;
	width: 50%;
	margin-left: 100px;
	margin-right: 100px;
}

.banner-row {
	display: flex;
	flex: 1;
	flex-direction: row;
	margin-top: 15px;
	line-height: 24px;
	font-size: 20px;
	padding: 30px;

	.partner-logo {
		width: 220px;
	}

	&.cpr-mobile {
		display: initial;
	}

	&.cpr-mobile {
		display: none;
	}

	@include medium {
		font-size: 18px;
		flex-direction: column;
		margin-top: 0;

		&.header-row {
			flex-direction: row !important;
		}

		.cpr-desktop {
			display: none !important;
		}

		&.cpr-mobile {
			display: initial !important;
		}
	}

	.banner-column {
		@include medium {
			margin-top: 5px;
		}

		font-size: 18px;
		display: flex;
		flex-direction: column;
		flex: 1;
		line-height: 1.6;
		margin-bottom: 32px;

		> div {
			flex: 1;
			align-items: flex-start;
		}

		&:nth-child(1) {
			flex: 2;
			min-width: 66%;
		}

		&:nth-child(2) {
			align-items: flex-end;
			display: flex;
		}
	}
}
