@import "./Variables.scss";

@font-face {
	font-family: "NORLYSHeadlineBold";
	src: url("./Assets/Fonts/NORLYSHeadline-Bold.otf") format("opentype");
	font-display: fallback;
  }
  
  @font-face {
	font-family: "NORLYSTextRegular";
	src: url("./Assets/Fonts/NORLYSText-Regular.otf") format("opentype");
	font-display: fallback;
  }
  
  @font-face {
	font-family: "NORLYSTextBold";
	src: url("./Assets/Fonts/NORLYSText-Bold.otf") format("opentype");
	font-display: fallback;
  }

:root {
	a {
		color: $Grey-100;
	}
}

body {
	margin: 0;
	font-family: "NORLYSTextRegular", "Arial", sans-serif;
}

.hidden-element-mobile {
	@include medium {
		display: none !important;
	}
}

.hidden-desktop {
	display: none;
}

.hidden-element {
	display: none !important;
}

.app-container {
	margin: 0 auto;
	margin-top: 50px;
	max-width: calc(1320px + 48px);
	padding-left: 24px;
	padding-right: 24px;

	@include small {
		padding-left: 0;
		padding-right: 0;
	}
}

button.Button {
	font-family: "NORLYSText-Regular";

	min-width: 200px;

	@include medium {
		min-width: auto;
	}
}

.bold {
	font-weight: bold;
}

.input-element-parent {
	font-family: "NORLYSText-Regular";

	> input {
		font-family: "NORLYSText-Regular";
	}

	.input-placeholder {
		font-family: "NORLYSText-Regular";
	}
}
.calender-container {
	.calender-input {
		font-family: "NORLYSText-Regular" !important;
	}
}

.email-parent {
	.input-element-parent {
		font-family: "NORLYSText-Regular" !important;
		.input-element {
			font-family: "NORLYSText-Regular" !important;
		}
		> input {
			font-family: "NORLYSText-Regular" !important;
		}

		.input-placeholder {
			font-family: "NORLYSText-Regular" !important;
		}
	}
}

.overlay-message {
	font-family: "NORLYSText-Regular" !important;
	font-weight: 500 !important;
}

.keyboard-padding {
	margin-bottom: 55vh;
}
