@import "../../../../../Variables.scss";

.google-recaptcha {
	display: flex;

	@include small {
		justify-content: center !important;
	}
}
.error-section {
	margin-top: 15px;
	color: indianred;
	padding-left: 15px;
}

.outer-container {
	display: flex;
	flex-direction: column;
	flex: 0.5;
	margin: 16px 36px 8px;
}

.text {
	font-size: 18px;
	line-height: 26px;
	text-align: left;
}

.nl-modal {
	overflow: hidden;
}
