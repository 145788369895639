@import "../../../../../Variables.scss";

.movingin-wrapper {
	font-family: "NORLYSText-Regular" !important;
	display: flex;
	margin-bottom: 15px;

	@include small {
		.box-button {
			width: 35%;
			min-width: auto;
		}
	}

	> div:nth-child(2) {
		margin-left: 20px;

		@include small {
			margin-left: 10px;
		}
	}
}

.datepicker-wrapper {
	margin-top: 20px;
	display: flex;

	&.datepicker-open {
		margin-bottom: 180px;
	}

	.datepicker-inner {
		position: relative;

		.tooltip-container {
			position: absolute;
			right: -100px;
			top: 15px;
		}
	}
}
