#overlayContainer{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    width: 100%;
    height: 100%;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    #overlayMessage{
        color:white;
    }
}