@import "../../../../../Variables.scss";

.emobility-wrapper {
	display: flex;
	margin: 3rem;

	@media (max-width: 768px) {
		margin: 1rem 2rem 0 1rem;
	}

	.nl-checkbox-layout {
		margin-top: 30px;
	}

	.checkbox-section {
		margin-top: 2rem;
	}

	.campaign-section {
		@include small {
			margin-bottom: 100px;
		}
	}

	.nl-checkbox-label {
		margin-top: 8px;
	}

	.month-select-title {
		font-family: "NORLYSText-Regular";
		margin-bottom: 10px;
	}

	.MuiInputBase-root {
		fieldset {
			border-color: darkgray !important;
		}
	}
}
