@import "../../../../../Variables.scss";

#card-wrapper{
	display: flex;
	gap:30px;

	@include small {
		flex-direction: column;
	}
}

.product-info {
	margin-top: 30px;
	padding: 15px;

	> a {
		text-decoration: underline;
		cursor: pointer;
		margin-left: 5px;
	}

	> img {
		height: 20px;
		vertical-align: middle;
		margin-right: 10px;
	}

	@include small {
		margin-bottom: 110px;
		margin-top: -40px;
		text-align: center;
	}
}

.productstep-modal {
	display: flex;
	flex-direction: column;

	p {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	img {
		width: 140px;
		justify-content: center;
		margin: 0 auto;
	}

	h1 {
		font-family: "NORLYSHeadline-Bold";
		display: flex;
		margin: 0 auto;

		margin-top: 10px;
		margin-bottom: 20px;
	}

	@include small {
		padding-bottom: 20%;
	}
}
.hidden-button-desktop {
	display: none;
	@include medium {
		display: initial;
	}
}
