.error-boundary {
  background-color: ghostwhite;
  color: black;
  width: 80%;
  margin: 0 auto;
  min-height: 200px;
  height: 80%;
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}