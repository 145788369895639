@import "../../../Variables.scss";

.column-section {
	display: flex;
	position: relative;
	padding: 5px;

	.nl-chevron .nl-chevron-content {
		@include medium {
			margin: 0 25px 0 8px !important;
		}
	}

	.tooltip-container {
		margin-left: 5px;
	}

	.suggestions {
		padding-right: 0 !important;
	}

	@include medium {
		padding-left: 0;
		width: 100%;
	}

	.input-element-parent {
		width: auto;
		margin-top: 0;
		margin-bottom: 0;

		.input-element {
			width: stretch;
			width: -webkit-fill-available;
			width: -moz-available;
		}
	}

	&.column-fullWidth {
		> div:nth-child(1) {
			min-width: 95%;

			@include medium {
				min-width: 88%;
			}
		}
	}

	&.fullWidth-mobile {
		@include medium {
			> div:nth-child(1) {
				min-width: 88%;
			}
		}

		&.no-tooltip {
			@include medium {
				> div:nth-child(1) {
					min-width: 96%;
					padding-right: 0;
				}
			}
		}
	}

	> *:not(.tooltip-container) {
		flex: 1;
		max-width: 48%;
		min-width: 48%;
		margin: 5px;

		&:nth-child(1) {
			@include medium {
				margin-right: 0;
			}
		}
	}

	> *.tooltip-container {
		min-width: 30px;
		margin-top: 20px;

		@include medium {
			margin-left: 5px;
		}
	}
}

.one-element {
	div {
		max-width: 100% !important;
		margin-right: 5px !important;
	}
}
