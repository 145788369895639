@import "../../../../../Variables.scss";

.address-step-desktop {
	@include medium {
		display: none;
	}

	.nl-chevron {
		overflow: inherit;
	}
}

.address-step-mobile {
	display: none;

	> div {
		display: flex;
		flex-direction: column;
		background-color: $Green-bright;
	}

	.nl-chevron-footer {
		background-color: $Green-bright;
		box-shadow: none;
		opacity: 0;
	}

	.address-mobile-container {
		line-height: 1.4;
		padding: $size4;
		height: 100vh;
		width: 100vw;
		overflow: hidden;
		overflow-y: auto;
		position: absolute;
		padding: 0;

		h1 {
			margin-top: 40px;
			margin-bottom: 0;
			font-family: "NORLYSHeadline-Bold";
			text-align: left;
			padding-left: 15px;
			padding-right: 15px;

			@include small {
				font-size: 150%;
			}

			@include extrasmall {
				font-size: 120%;
				margin-top: 10px;
			}
		}

		p {
			text-align: left;
			padding: 0 10px 0 10px;
		}

		> div {
			padding: 5px;
		}

		> img {
			display: flex;
			margin: 0 auto;
			max-width: 180px;
		}

		.address-text-section {
			line-height: 1.6;
			margin-top: $size16;
			padding: 0 10px 0 10px;
			text-align: left;

			@include extrasmall {
				font-size: 80%;
			}
		}
	}

	@media screen and(max-height:700px) {
		.address-mobile-container {
			> img {
				width: 130px;
			}

			> h1 {
				margin-top: 20px;
			}
		}
	}

	@media screen and(max-height:600px) {
		.address-mobile-container {
			> img {
				width: 120px;
			}

			> h1 {
				margin-top: 20px;
			}
		}
	}

	@media screen and(max-height:500px) {
		.address-mobile-container {
			> img {
				width: 110px;
			}

			> h1 {
				margin-top: 20px;
			}
		}
	}

	@include medium {
		display: initial !important;
	}
}
