@import "../../../../../Variables.scss";

.receipt-step {
	position: fixed;
	background-color: white;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0 !important;
	overflow: hidden;
}

.receipt-container {
	background-color: $Green-bright;
	border-radius: 6px;
	font-size: 18px;

	.receipt-content {
		max-width: 800px;
		font-family: "NORLYSText-Regular";
	}

	> div {
		padding: 0 0 0 20%;
		text-align: left;

		@include small {
			padding: 12px !important;
			text-align: left;
		}
	}

	@include small {
		width: 100vw;
		left: 0;
		margin: 0;
		padding: 0;
		min-height: fill-available;
		min-height: -webkit-fill-available;
		min-height: -moz-available;
		overflow: hidden;

		> div {
			padding: 10px;
		}
	}

	.receipt-text {
		margin: 32px 0 32px 0;
	}

	h1,
	h3 {
		font-family: "NORLYSHeadline-Bold";
	}

	h1 {
		font-size: 60px;
		padding-top: 60px;

		@include small {
			font-size: 51px;
			padding-top: 0;
		}
	}

	h3 {
		font-size: 40px;
		margin-top: -30px;

		@include small {
			font-size: 24px;
		}
	}

	.receipe-footer {
		margin: 60px 0 48px 0;
		padding-bottom: 60px;
		text-align: center;

		@include small {
			padding-bottom: 0;
		}
	}

	.advantages-text {
		text-align: center;
	}

	.bullets-section {
		margin-bottom: 32px;

		li {
			margin-bottom: 5px;
		}
	}
}

.voucher-content {
	background-color: white;
	width: 648px;
	min-height: 400px;
	border-radius: 6px;
	text-align: center;
	font-family: "NORLYSText-Regular";
	font-size: 16px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	margin-bottom: 25px;
	box-shadow: 0px 3px 54px rgba(0, 0, 0, 0.27);

	.voucher-title {
		font-size: 54px;
		font-family: "NORLYSHeadline-Bold";
		font-weight: bold;
		margin-top: 20px;
	}

	.voucher-info {
		font-size: 34px;
		font-family: "NORLYSText-Bold";
		margin-top: 20px;
		margin-bottom: 25px;
	}

	.voucher-abovetext {
		font-size: 18px;
	}

	.voucher-code {
		margin: 1rem 0;
		font-size: 40px;
		font-family: "NORLYSText-Bold";

		@include small {
			font-size: 120%;
		}
	}

	.voucher-belowtext {
		margin-top: 10px;
	}

	.voucher-footer {
		margin-top: auto;
		margin-bottom: 25px;
		line-height: 24px;
	}

	@include small {
		width: 100%;
	}

	> div {
		flex-direction: row;
	}
}

.left-aligned {
	text-align: left !important;
}
